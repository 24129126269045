<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ item.customer_goods_barcode }} - {{ item.sku }}
    </v-card-title>
    <v-card-text>{{ $t("labels.update_goods_price_description") }}</v-card-text>

    <v-card-text v-if="itemProperty.id">
      <v-row dense>
        <v-col cols="12" v-if="customer.id_company_type == 2">
          <v-autocomplete
            v-model.number="itemProperty.vat"
            class="c-input-xs mb-3"
            :label="$t('labels.vat')"
            :items="vatOptions"
            dense
            outlined
            clearable
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <input-number
            v-model.number="itemProperty.price"
            class="c-input-xs mb-3"
            type="number"
            :label="$t('labels.price')"
            dense
            outlined
            clearable
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <input-number
            v-model.number="itemProperty.wholesale_price"
            class="c-input-xs mb-3"
            type="number"
            :label="$t('labels.wholesale_price')"
            dense
            outlined
            clearable
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          v-if="checkPermission(['administration_goods_insurance'])"
        >
          <input-number
            v-model.number="itemProperty.insurance_value"
            class="c-input-xs mb-3"
            type="number"
            :label="$t('labels.insurance_value')"
            dense
            outlined
            clearable
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <date-picker-model
            v-model="itemProperty.from_date"
            :label="$t('labels.from_date')"
            :min-date="currentDate"
            :single-line="false"
          ></date-picker-model>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn color="green darken-1" text @click="confirm">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment/moment";
import { httpClient } from "@/libs/http";
import { VN_VAT_OPTIONS } from "@/libs/const";

export default {
  name: "UpdatePrice",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    customer: {},
    itemProperty: {},
    vatOptions: [...VN_VAT_OPTIONS],
  }),
  computed: {
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
  },
  mounted() {
    const { customer } = window.me;
    this.customer = { ...customer };
    this.itemProperty = {
      ...this.item,
      from_date: moment().format("YYYY-MM-DD"),
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async confirm() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods-update-price", {
          id: this.item.id,
          ...this.itemProperty,
        });
        this.isLoading = false;
        this.$emit("refreshData");
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
